<template>
  <app-tabs v-model="active">
    <el-tab-pane label="单点菜品">
      <div class="toolbar">
        <div class="t_action">
          <el-radio-group
            v-if="myCanteen == 999"
            class="t_group"
            fill="#E6A23C"
            v-model="fromData.canteenType"
            @input="changeCanteenType"
          >
            <el-radio-button :label="1">一食堂</el-radio-button>
            <el-radio-button :label="2">二食堂</el-radio-button>
          </el-radio-group>

          <el-button-group class="t_group">
            <el-button
                :loading="loading"
                icon="el-icon-arrow-left"
                @click="getPrevious"
            >
              上周
            </el-button>
            <el-button
                :loading="loading"
                icon="el-icon-refresh-left"
                @click="getWeekData()"
            >
              返回
            </el-button>
            <el-button
                :loading="loading"
                icon="el-icon-arrow-right"
                @click="getNext"
            >
              下周
            </el-button>
          </el-button-group>
          <el-button-group class="t_group">
            <el-button
                :loading="loading"
                :disabled="disabled"
                icon="el-icon-check"
                @click="submitSave(0)"
            >
              保存
            </el-button>
            <el-button
                :loading="loading"
                :disabled="disabled"
                icon="el-icon-finished"
                type="primary"
                @click="getPublish"
            >
              发布
            </el-button>
          </el-button-group>
          <el-button-group class="t_group">
            <el-button icon="el-icon-delete" @click="clearInsertMenu">清除选择</el-button>
          </el-button-group>
          <span class="group">当前日期：{{fromData.startDate}}～{{fromData.endDate}}</span>
        </div>
      </div>
      <div class="con-page" v-loading="loading">
        <div class="con-header">
          <table class="c_table">
            <tr class="center">
              <th class="td_type">餐类型</th>
              <th class="td_wid" v-for="(item, index) in weekData" :key="index">
                {{item.date}},{{item.week}}
              </th>
            </tr>
          </table>
        </div>
        <div class="con-box">
          <div class="con-box1">
            <table v-for="(value, key) in menuItem" :key="key" class="c_table" :style="'background: ' + value.bg">
              <tr>
                <td class="center td_type">
                  <div>{{value.name}}</div>
                  <div>
                    <el-button style="width:80%" @click="batchInsertMenu(value.type)" size="mini" >
                      批量加
                    </el-button>
                  </div>
                </td>
                <td class="td_wid" v-for="(item, index) in weekData" :key="index">
                  <div v-for="(item1, index1) in groupList[value.type]" :key="index1">
                    <div v-if="item1.menuDate == item.date" class="menu-item"
                        :class="{'menu-item-old': !item1.id}">
                      <div>
                        <div class="menu-name">
                          <span class="auto_con" :class="{'menu-name1': item1.isOut == 1}">{{item1.name}}</span>
                          <span class="price">￥{{Number(item1.price)}}</span>
                        </div>
                        <div class="menu-info">
                          <span>总{{item1.totalNum}}</span>
                          <span>已约{{item1.subscribeNum}}</span>
                          <span>已用{{item1.usedNum}}</span>
                          <span>未用{{item1.unusedNum}}</span>
                        </div>
                      </div>
                      <div class="menu-info menu-opt">
                        <span @click="editMenu(item1.index, item1)">修改</span>
                        <span v-if="!item1.id || item1.subscribeNum < 1" @click="delMenu(item1)">删除</span>
                      </div>
                    </div>
                  </div>
                  <div class="menu-jia">
                    <el-button @click="getCookbook(value.type, [{date: item.date, type: value.type}])" type="primary" size="small"
                        plain style="margin-right:20px;"  icon="el-icon-circle-plus-outline">
                      单加
                    </el-button>
                    <el-checkbox-group @change="checkboxSelect" v-model="insertMenu" size="small">
                      <el-checkbox :label="item.date + ',' + value.type" border>批加</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="菜品管理" lazy>
      <library />
    </el-tab-pane>
    <el-dialog
        :visible.sync="menuDialog"
        append-to-body
        title="选择菜品"
        width="59vw"
    >
      <div v-loading="loading" class="menu-view">
        <div style="display: flex;justify-content: space-around;">
          <el-input
              ref="input"
              v-model.trim="searchName"
              class="search"
              suffix-icon="el-icon-search"
              clearable
              placeholder="可输入要搜索菜单名称"
              @input="inputMenu"
          />
          <el-button @click="searchName='';getCookbook()">刷新</el-button>
        </div>
        <el-divider />
        <app-menu-view
            :items="menuList"
            disable-remove
            view-height="460px"
            @select="selectMenu"
        />
      </div>
    </el-dialog>
    <el-dialog
        title="[添加/修改]菜品"
        :visible.sync="editData.show"
        width="40vw" @close="initMenu">
      <el-form label-width="120px">
        <el-form-item label="菜品名称">
          <el-input v-model="editData.menu.name" />
        </el-form-item>
        <el-form-item label="选择总数量">
          <el-radio-group v-model="editData.menu.totalNum">
            <el-radio-button :label="10">10份</el-radio-button>
            <el-radio-button :label="20">20份</el-radio-button>
            <el-radio-button :label="30">30份</el-radio-button>
            <el-radio-button :label="40">40份</el-radio-button>
            <el-radio-button :label="50">50份</el-radio-button>
            <el-radio-button :label="100">100份</el-radio-button>
            <el-radio-button :label="1000">1000份</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="输入总数量">
          <el-input-number v-model="editData.menu.totalNum" :min="1" :step="10" />
        </el-form-item>
        <el-form-item label="最多预约数">
          <el-input-number v-model="editData.menu.maxNum" :min="1" :max="10" :step="1" />
          <span>单价￥{{editData.menu.price}}元</span>
        </el-form-item>
        <el-divider></el-divider>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="initMenu">取 消</el-button>
        <el-button type="primary" @click="submitMenu()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="[添加/修改]菜品"
        :visible.sync="createData.show"
        width="40vw" @close="initCreateMenu">
      <el-form label-width="120px">
        <el-form-item label="菜品名称">
          <el-input v-model="createData.menu.name" />
        </el-form-item>
        <el-form-item label="选择总数量">
          <el-radio-group v-model="createData.menu.totalNum">
            <el-radio-button :label="10">10份</el-radio-button>
            <el-radio-button :label="20">20份</el-radio-button>
            <el-radio-button :label="30">30份</el-radio-button>
            <el-radio-button :label="40">40份</el-radio-button>
            <el-radio-button :label="50">50份</el-radio-button>
            <el-radio-button :label="100">100份</el-radio-button>
            <el-radio-button :label="1000">1000份</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="输入总数量">
          <el-input-number v-model="createData.menu.totalNum" :min="1" :step="10" />
        </el-form-item>
        <el-form-item label="最多预约数">
          <el-input-number v-model="createData.menu.maxNum" :min="1" :max="10" :step="1" />
          <span>单价:{{Number(createData.menu.price)||0}}元</span>
          <span>，已预约:{{createData.menu.subscribeNum || 0}}人</span>
        </el-form-item>
        <el-divider></el-divider>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="initCreateMenu">取 消</el-button>
        <el-button type="primary" @click="submitCreateMenu()">确 定</el-button>
      </span>
    </el-dialog>
  </app-tabs>
</template>

<script>
import library from './library';
import request from '@/utils/request';
import dayjs from 'dayjs';
export default {
  name: 'SinglePoint',
  components: { library },
  data() {
    return {
      editData: {
        show: false,
        menu: {},
      },
      createData:{
        show: false,
        menu: {},
      },

      dialog: undefined,
      visible: false,

      menuDialog: false,
      searchName: '',
      searchType: 1,
      menuList: [],
      timer: null,
      insertMenu: [],
      insertMenuList: [],
      saveMenuData: [],

      menuItem: [{
        type: 1,
        name: '早餐',
        bg: '#fdf6ec'
      }, {
        type: 2,
        name: '中餐',
        bg: '#f4fff4'
      }, {
        type: 3,
        name: '晚餐',
        bg: '#eff8fb'
      }],
      loading: false,
      disabled: false,
      startDate: '',
      weekData: [],
      fromData: {
        startPage: 1,
        pageSize: 500,
        types: [1, 2, 3],
        isSingle: 1,
        canteenType: 1,
        startDate: '',
        endDate: '',
      },
      active: '0',
      myCanteen: 0,
      dataList: [],
      groupList: [],
    };
  },
  // beforeCreate() {
  //   console.log('beforeCreate - 初始化配置1111111111');
  // },
  async created() {
    this.myCanteen = Number(sessionStorage.getItem('canteenType')) || 0;
    if (this.myCanteen == 2){
      this.fromData.canteenType = 2
    }
    await this.getWeekData();
  },
  // mounted() {
  //   console.log('mounted - DOM 操作3333333333333');
  // },
  methods: {
    // 切换食堂
    async changeCanteenType(e) {
      this.fromData.canteenType = e;
      await this.getMenuList();
    },
    //取得指定日期的一周日期号数
    async getWeekData(day) {
      if (!day) {
        //默认下1周
        day = dayjs().add(1, 'week').startOf('week').format('YYYY-MM-DD');
      }
      this.startDate = day
      this.fromData.startDate = dayjs(day).startOf('week').format('YYYY-MM-DD')
      this.fromData.endDate = dayjs(day).endOf('week').format('YYYY-MM-DD')
      this.weekData = [];
      for (let j = 0; j < 7; j++) {
        let d = dayjs(day).add(j, 'day');
        this.weekData.push({
          date: d.format('YYYY-MM-DD'),
          week: d.format('ddd'),
        });
      }
      await this.getMenuList();
    },
    // 上一周
    async getPrevious() {
      let d = dayjs(this.startDate).subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
      await this.getWeekData(d);
    },
    // 下一周
    async getNext() {
      let d = dayjs(this.startDate).add(1, 'week').startOf('week').format('YYYY-MM-DD');
      await this.getWeekData(d);
    },
    // 保存，即下架
    async submitSave(isOut = 0) {
      // if (![1, 2].includes(this.fromData.canteenType)) {
      //   return this.$alert('您未分配所属食堂');
      // }
      let data = isOut === 0 ? this.saveMenuData : this.dataList;
      if (isOut === 1){
        data.forEach(item => (item.isOut = isOut));
      }
      await request.post('/canteen/menu/saveMenu', data);
      await this.getMenuList();
    },
    // 发布，即上架
    async getPublish() {
      try {
        await this.$confirm('确认发布吗?', '提示', {
          type: 'warning' // success error info warning
        });
        await this.submitSave(1);// 值1为上架
      } catch (e) {
        console.log(e);
      }
    },
    //取得菜单列表
    async getMenuList() {
      this.loading = true;
      this.dataList = [];
      const {data, code} = await request({
        url: '/canteen/menu/getMenuList',
        method: 'post',
        data: this.fromData
      });
      this.loading = false;
      this.dataList = code == 200 ? (data.records || []) : [];
      this.groupData();
      // console.log('getMenuList', this.dataList);
    },
    //对菜品按类别分组
    groupData() {
      this.groupList = [[], [], [], []];
      this.dataList.forEach((item, index) => {
        this.groupList[item.type].push({...item, index});
      });
    },
    //删除菜品
    delMenu(item) {
      // console.log('delMenu', item);
      this.$confirm('确认删除吗?', '提示', {
        type: 'warning' // success error info warning
      }).then(async () => {
        let id = item.id || 0;
        if (id) {
          const {code} = await request({
            url: '/canteen/menu/delMenu',
            method: 'post',
            data: {id: id}
          });
        }
        if (item.menu_id) {
          let index = this.saveMenuData.findIndex(val => val.menu_id === item.menu_id);
          if (index > -1) {
            this.saveMenuData.splice(index, 1);
          }
        }
        this.dataList.splice(item.index, 1);
        this.groupData();
      }).catch(e=>{});
    },
    // addMenu(type, date) {
    //   this.$parent.dialog = this.$parent.creator
    //   this.$parent.visible = true
    // },
    //修改菜单
    editMenu(index, item) {
      this.menuDialog = false
      this.createData.menu = item;
      this.createData.show = true;
    },
    // 初始化
    initCreateMenu(){
      this.createData.show = false;
      this.createData.menu = {};
    },
    //选择菜品或修改菜单后 确认
    async submitCreateMenu() {
      let data = this.createData.menu;
      let val = {}
      let d = JSON.parse(JSON.stringify(data));
      this.dataList.forEach((item, index) => {
          if (item.menuDate === d.menuDate) {
            this.dataList[index] = d
            val = this.dataList[index]
          }
      })
      if(val.id){
        this.loading = true;
        const {code} = await request({
          url: '/canteen/menu/updateMenu',
          method: 'post',
          data: val
        });
        this.loading = false;
        if (code == 200) {
          this.createData.show = false
          this.$alert('更新成功');
        }
      }
      this.initCreateMenu();
      this.groupData();
    },
    //勾选菜品后 点击批量添加
    async batchInsertMenu(type) {
      if (!this.insertMenuList.length) {
        return this.$alert('请勾选要添加日期');
      }
      let data = [];
      this.insertMenuList.forEach((item, index) => {
        if (item.type == type) {
          data.push(item);
        }
      });
      if (!data.length) {
        return this.$alert('请勾选本类型要添加日期');
      }
      this.searchType = type;
      this.insertMenuList = data;
      await this.getCookbook();
    },
    // 清除勾选的菜品
    clearInsertMenu() {
      this.insertMenu = [];
      this.insertMenuList = [];
    },
    // 勾选批量添加菜品
    checkboxSelect(e) {
      this.insertMenuList = [];
      if (e.length) {
        e.forEach((item, index) => {
          let v = item.split(',');
          this.insertMenuList.push({
            date: v[0],
            type: v[1],
          })
        });
      }
    },
    // 取得 搜索框防抖
    inputMenu() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getCookbook();
      }, 500)
    },
    //点击取得菜品
    async getCookbook(type, obj) {
      if (type) {
        this.searchType = type;
      }
      if (obj) {
        this.insertMenuList = obj;
      }
      const params = {type: this.searchType, name: this.searchName, pageSize: 500, isSingle: 1};
      const { data } = await request.get('/canteenCookbook', {params});
      this.menuList = data.data || [];
      this.menuDialog = true
      // console.log('getCookbook', data)
    },
    //选择菜品
    selectMenu(e) {
      this.menuDialog = false
      this.editData.menu = {
        menu_id: e.id || 0,
        picPath: e.picPath,
        name: e.name,
        type: e.type,
        category: e.category,
        price: Number(e.price),
        totalNum: e.totalNum || 50,
        maxNum: e.maxNum || 2,
        menuDate: e.menuDate || '',
        isOut: e.isOut || 0,
        isSet: e.isSet || 0,
        canteenType: e.canteenType || this.fromData.canteenType,
        tableX: 0,
        tableY: 0,
        description: e.description || '',
        isSingle: 1
      };
      this.editData.show = true;
    },
    // 初始化
    initMenu(){
      this.editData.show = false;
      this.editData.menu = {};
    },
    //选择菜品或编辑菜单后 确认
    async submitMenu() {
      // console.log('insertMenuList', this.insertMenuList);
      let data = this.editData.menu, menu = [];
      this.insertMenuList.map((item, index) => {
        let d = JSON.parse(JSON.stringify(data));
        d.menuDate = item.date;
        d.type = item.type;
        if (this.saveMenuData.findIndex(val => val.menuDate == item.date && val.type == item.type && val.menu_id == d.menu_id) < 0) {
          this.saveMenuData.push(d);
          menu.push(d);
        }
      });
      // this.clearInsertMenu();
      this.initMenu();
      if (menu.length) {
        this.dataList = this.dataList.concat(menu);
        this.groupData();
      }
    },

  }
};
</script>
<style lang="scss" scoped>
/* 隐藏滚动条 */
::-webkit-scrollbar{width:0;height:0;}
.con-page {
  margin-top: 12px;
  padding: 12px;
  background-color: #fff;
  height: calc(100vh - 152px);
  color: #555;
  font-size: 14px;
}
.auto_con{
  flex: 1 1 0%;
}
.center{
  justify-content: center;
  align-items: center;
  text-align: center;
}
.c_table{width: 100%;}
.c_table th,.c_table td{display:table-cell;vertical-align:top;border:1px solid #ddd;}
.c_table th{text-align: center;}
.c_table td{
  min-height: 30px;
}
.td_type{
  width: 6em;
  vertical-align:middle!important;
}
.td_wid{
  width: 13.5%;
}
.td_wid:last-child{
  width: auto;
}
.con-header {
  line-height: 36px;
}
.con-box {
  height: calc(100vh - 296px);
  overflow: auto;
  -moz-appearance:none!important; /* 滚动条样式 */
  padding-bottom: 3em;
}
.menu-item{
  padding: 5px;
  border-bottom: 1px dotted #999;
  line-height: 1.8;
  font-size: 13px;
}
.menu-item-old{
  background:#ffe4bb;
}
.menu-name{
  display: flex;
  font-size: 14px;
  color: #333;
  .price{
    color: red;
    font-weight: 800;
  }
}
.menu-name1{
  color: #049346;
  font-weight: 800;
}
.menu-info{
  font-size: 12px;
  color: #999;
  display: flex;
  text-align: center;
  span{
    flex: 1;
  }
  span:last-child{
    text-align: right;
  }
  span:first-child{
    text-align: left;
  }
}
.menu-opt{
  color: #e38b05;
  span{
    cursor: pointer;
  }
  span:hover{
    background: #f8d8c5;
  }
}
.menu-jia{
  display: flex;
  justify-content: center;
  padding: 10px;
}
.menu-view {
  display: flex;
  flex-direction: column;
  .search {
    width: 360px;
  }
}
.toolbar {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;

  .title {
    display: flex;
    align-items: center;
  }
  .t_action {
    .t_group {
      margin-right: 12px;
    }
  }
}
//::v-deep {
//  .el-table table {
//    border: thin solid black;
//    border-bottom: none;
//    border-right: none;
//  }
//
//  .el-table__cell {
//    border-bottom: thin solid black !important;
//    border-right: thin solid black !important;
//    cursor: pointer;
//  }
//}
</style>
